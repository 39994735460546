<template>
  <div class="nav bg-white align-items-center">
    <!-- <div class="col-lg-10 offset-lg-1"> -->
    <b-container class="py-3 d-flex">
      <b-row class="w-100 m-0">
        <div class="col-6">
          <a href="/"
            ><img src="../assets/directveilig-logo.png" class="nav__logo"
          /></a>
        </div>
        <div class="col-6 text-right">
          <div class="d-flex ml-auto">
            <p class="text-right mr-0 mr-md-3 mb-0 ml-auto nav_text">
              Erkend betrouwbaar en <br />
              gecertificeerd beveiligingsbedrijf
            </p>
            <img
              src="../assets/ccv-logo-nav.png"
              class="mr-0 mr-md-3 d-none d-md-block"
            />
            <img
              src="../assets/justitie-logo-nav.png"
              class="d-none d-lg-block"
            />
          </div>
        </div>
      </b-row>
    </b-container>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<style scoped lang="scss">
.nav {
  height: 100px;
  @media only screen and (max-width: 766px) {
    height: 75px;
  }
  @media only screen and (max-width: 376px) {
    height: 95px !important;
  }
  .nav__logo {
    @media only screen and (max-width: 766px) {
      width: 100%;
    }
  }
  .nav_text {
    @media only screen and (max-width: 766px) {
      font-size: 12px;
    }
  }
  & p {
    color: #143148 !important;
    opacity: 0.8;
  }
  &__logo {
    @media only screen and (max-width: 766px) {
      width: 100%;
    }
  }
}
</style>
