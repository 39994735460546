<template>
  <div class="cvv py-5">
    <div class="col-lg-10 offset-lg-1 py-3">
      <b-container>
        <b-col cols="12" md="8">
          <h2 class="text-white mb-3">
            CVV - gecertificeerd beveiligingsbedrijf
          </h2>
        </b-col>
        <b-col cols="12" md="7">
          <p class="text-white">
            Als BORG-erkend specialist met meer dan 20 jaar ervaring bieden wij
            de beste beveiligingsoplossingen voor zowel particulieren als
            bedrijven. Een BORG-certificaat is het schriftelijke bewijs van een
            goede inbraakbeveiliging van uw woning of bedrijfspand. Dit document
            is belangrijk, want het verzekert u naast een goede beveiliging van
            diverse voordelen bij de verzekering.
          </p>
          <img src="../../assets/CCVBORGlogo.png" width="40px" />
        </b-col>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.cvv {
  background: url(../../assets/achtergrond5.png);
  background-size: cover;
  background-repeat: no-repeat;
  & p {
    font-size: 14px;
    font-family: "Roboto-Light";
  }
}
</style>
