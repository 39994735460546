<template>
  <div class="bedankt">
    <div class="col-lg-8 offset-lg-2 py-5">
      <b-container class="p-4 p-md-5 bg-white shadow-lg text-center">
        <h3>Bedankt voor uw deelname.</h3>
        <h5>
          Een van onze beveiligingsexperts zal in de komende dagen telefonisch
          contact met je opnemen om het inbraak risico en je wensen door te
          nemen.
        </h5>
        <img src="../assets/directveilig-logo.png" class="mt-2" />
        <img
          referrerpolicy="no-referrer-when-downgrade"
          :src="`https://republish.verbeterthuis.nl/m/5452/f0f1d370d4ad/?event=6364&unique_conversion_id=${profId}`"
          style="width: 1px; height: 1px; border: 0px"
        />
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Bedankt",
  computed: mapGetters(["profId"]),
};
</script>

<style lang="scss">
*::-webkit-scrollbar {
  display: none;
}
.bedankt {
  background: url(../assets/background.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: calc(100vh + 70px);
  @media only screen and (max-width: 766px) {
    height: calc(100vh + 100px) !important;
  }
  @media only screen and (max-width: 376px) {
    height: calc(100vh + 235px) !important;
  }
}
</style>
