<template>
  <div class="hero">
    <!-- <div class="col-lg-10 offset-lg-1"> -->
    <b-container class="pt-3 h-100">
      <b-row class="pt-4 h-100">
        <b-col lg="8" sm="12" class="">
          <div class="bg-white p-5 my-md-5 mt-0 leftContent">
            <h2 class="font-weight-bold">
              Wist je dat: In de herfst- en wintermaanden het aantal
              woninginbraken stijgt?
            </h2>
            <img src="../../assets/Lijn 1.png" />
            <div class="row">
              <div class="col-7">
                <h6>
                  <b
                    >Verhoog de veiligheid van jouw gezin, start de
                    Veiligheidscheck van DirectVeilig.</b
                  >
                </h6>
                <p>
                  Beantwoord 5 vragen en kom er achter hoe <b>veilig</b> uw
                  woning precies is.
                </p>
                <b-button
                  variant="warning"
                  size="lg"
                  class="btn-bg text-white shadow-lg px-5 py-2 d-md-block d-none mt-5"
                  @click="
                    () => {
                      this.$router.push(`questions`);
                    }
                  "
                  >Start de veiligheidscheck &nbsp; ></b-button
                >
              </div>
              <div class="col-5">
                <img
                  src="../../assets/RING Videodeurbel combine.png"
                  class="leftContent__img"
                />
              </div>
            </div>
            <b-button
              variant="warning"
              size="lg"
              class="btn-bg text-white shadow-lg px-4 d-md-none d-block"
              @click="
                () => {
                  this.$router.push(`questions`);
                }
              "
              >Start de veiligheidscheck &nbsp; ></b-button
            >
          </div>
        </b-col>
        <b-col lg="4" sm="12" class="mt-auto">
          <img
            src="../../assets/telefoon-tweet.png"
            width="100%"
            height="100%"
            class="mt-auto d-flex"
          />
        </b-col>
      </b-row>
    </b-container>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Hero",
  data() {
    return {
      site_subid: "",
      publisher_id: "",
    };
  },
  methods: {
    ...mapActions(["getClickId"]),
  },
  created() {
    this.site_subid = this.$route.query.oa_id;
    this.publisher_id = this.$route.query.site;

    let subId = this.site_subid;
    let publisherId = this.publisher_id;
    this.getClickId({ subId, publisherId });
  },
};
</script>

<style lang="scss">
.hero {
  background: url(../../assets/baner-bg1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;

  & h2 {
    color: #143148 !important;
    font-family: Roboto-Bold;
    @media only screen and (max-width: 766px) {
      font-size: 20px !important;
    }
  }
  & h6 b {
    color: #337ab7 !important;
    font-size: 18px;
    @media only screen and (max-width: 766px) {
      font-size: 18px !important;
    }
  }
  & p {
    color: #143148 !important;
    @media only screen and (max-width: 766px) {
      font-size: 14px !important;
    }
  }
  & .btn-bg {
    background: #f7852c !important;
    border: #f97e20 !important;
    color: white !important;
    border-radius: 10px !important;
    @media only screen and (max-width: 766px) {
      font-size: 16px !important;
    }
  }
  .leftContent {
    height: 450px;
    margin-bottom: 5rem !important;
    @media only screen and (max-width: 766px) {
      margin-bottom: 2rem !important;
    }
  }
  .leftContent__img {
    position: absolute;
    z-index: 1;
    margin-top: -60px;
    @media only screen and (max-width: 1199.98px) {
      margin-top: -105px;
      margin-left: -30px !important;
    }
    @media only screen and (max-width: 766px) {
      margin-top: 0px;
      width: 160px !important;
      margin-left: -30px !important;
    }
  }
}
</style>
