<template>
  <div class="bg-white het py-5">
    <div class="col-lg-10 offset-lg-1">
      <b-container class="text-center">
        <h2><b>Het grootste beveiligingsbedrijf van Nederland</b></h2>
        <b-row class="mt-4">
          <b-col cols="6" md="3">
            <img src="../../assets/icoon-1.png" class="mb-2" />
            <h5>
              <b
                >Draadloos of <br />
                bekabeld</b
              >
            </h5>
            <p>
              Dankzij de nieuwste technologie werkt ons alarmsysteem draadloos
              tot wel 2 kilometer afstand. Uiteraard kan het ook bekabeld.
            </p>
          </b-col>
          <b-col cols="6" md="3">
            <img src="../../assets/icoon-2.png" class="mb-2" />
            <h5>
              <b
                >24/7 <br />
                bereikbaar</b
              >
            </h5>
            <p>
              Behoefte aan professioneel advies door een beveiligingsadviseur?
              Dat is mogelijk, wij komen namelijk vrijblijvend bij u op locatie.
            </p>
          </b-col>
          <b-col cols="6" md="3">
            <img src="../../assets/icoon-3.png" class="mb-2" />
            <h5>
              <b
                >Bedienen met uw <br />
                smartphone</b
              >
            </h5>
            <p>
              Naast het in en uitschakelen via het codebedienpaneel kunt u ook
              het alarm bedienen doormiddel van onze handige app.
            </p>
          </b-col>
          <b-col cols="6" md="3">
            <img src="../../assets/icoon-4.png" class="mb-2" />
            <h5>
              <b
                >Installatie en service <br />
                door vakmensen</b
              >
            </h5>
            <p>
              Besteld u bij ons een alarmsysteem dan word deze ook vakkundig
              geïnstalleerd door onze eigen borg erkende installateurs.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.het {
  & h2 {
    color: #153856 !important;
  }
  & p {
    font-size: 14px !important;
  }
}
</style>
