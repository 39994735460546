<template>
  <div class="duizenden py-5 bg-white">
    <div class="col-lg-10 offset-lg-1 py-3">
      <b-container>
        <b-row>
          <b-col cols="12" md="6">
            <h3 class="mb-3">Duizenden tevreden klanten</h3>
            <p>
              DirectVeilig.nl beschikt over 6 vestigingen door heel Nederland en
              is hierdoor in staat een gevarieerd klantenbestand snel en op maat
              te bedienen. Zowel in eigen land als in de Benelux en Duitsland
              verrichten wij onze dienstverlening voor duizenden tevreden
              klanten. Ons beveiligingsbedrijf staat bekend om de hoge kwaliteit
              van beveiliging en snelle herstel in geval van een eventuele
              calamiteit.
            </p>
            <b-button
              variant="warning"
              size="lg"
              class="btn-bg text-white shadow-lg px-4 mt-3"
              @click="
                () => {
                  this.$router.push(`questions`);
                }
              "
              >Gratis berekening &nbsp; ></b-button
            >
          </b-col>

          <b-col cols="12" md="6">
            <img
              src="../../assets/googleReviews.png"
              width="90%"
              class="mt-5 mt-md-0"
            />
            <div class="offset-lg-2">
              <div class="ml-5 ml-md-0 mt-3">
                <div class="row align-items-center px-2">
                  <img src="../../assets/google-gvh.png" />
                  <p class="mb-0 mx-2"><b>GVH</b></p>
                  <img src="../../assets/reviewStars.png" />
                </div>
                <p class="mt-2">
                  Zeer vakkundig en betrouwbaar bedrijf. Afspraken worden
                  nagekomen en in alles wordt meegedacht. Communicatie verloopt
                  soepel.
                </p>
              </div>
              <div class="ml-5 ml-md-0 mt-3">
                <div class="row align-items-center px-2">
                  <img src="../../assets/google-hussein.png" />
                  <p class="mb-0 mx-2"><b>hussein naseef</b></p>
                  <img src="../../assets/reviewStars.png" />
                </div>
                <p class="mt-2">
                  Hele goede service en vriendelijke medewerkers prijstechnisch
                  zijn ze nummer 1. Klanten tevreden houden staat hoog in het
                  vaandel ga zo door!
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.duizenden {
  & p {
    font-size: 14px;
    font-family: "Roboto-Light";
  }
  & .btn-bg {
    color: white !important;
    background: #f7852c !important;
    border: #f97e20 !important;
    border-radius: 10px !important;
  }
}
</style>
