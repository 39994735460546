<template>
  <div class="home">
    <Navbar />
    <LandingView />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import LandingView from "../components/LandingView.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    LandingView,
  },
};
</script>
