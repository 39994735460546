<template>
  <div class="home">
    <Navbar />
    <AfterSubmit />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import AfterSubmit from "../components/AfterSubmit.vue";

export default {
  name: "Bedankt",
  components: {
    Navbar,
    AfterSubmit,
  },
};
</script>
