<template>
  <div class="vele py-5">
    <div class="col-lg-10 offset-lg-1 py-3">
      <b-container>
        <b-col cols="12" md="7">
          <h3 class="text-white mb-3">
            Vele beveiligingopties binnen handbereik
          </h3>
        </b-col>
        <b-col cols="12" md="6">
          <p class="text-white">
            Bij DirectVeilig.nl bieden we uiteenlopende veiligheidsoplossingen
            zoals alarmsystemen, camerasystemen, voertuigvolgsystemen en een
            deurbelcamera. Hierbij heeft u voor elke oplossing de keuze uit
            meerdere pakketten. Standaard inbegrepen bij elk pakket en product
            is een handige applicatie voor op uw smartphone of tablet, die
            nagenoeg eindeloze beveiligingsopties biedt.
          </p>
          <b-button
            variant="warning"
            size="lg"
            class="btn-bg text-white shadow-lg px-4 mt-3"
            @click="
              () => {
                this.$router.push(`questions`);
              }
            "
            >Gratis berekening &nbsp; ></b-button
          >
        </b-col>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.vele {
  background: url(../../assets/achtergrond.png);
  background-size: cover;
  background-repeat: no-repeat;
  & p {
    font-size: 14px;
    font-family: "Roboto-Light";
  }
  & .btn-bg {
    background: #f7852c !important;
    border: #f97e20 !important;
    color: white !important;
    border-radius: 10px !important;
  }
}
</style>
