<template>
  <div class="bg-white het py-5">
    <div class="col-lg-10 offset-lg-1">
      <b-container class="text-center">
        <h2 class="mb-4">
          <b>Directveilig.nl installeert ook bij bekend Nederland</b>
        </h2>
        <iframe
          class="my-4"
          width="60%"
          height="315"
          src="https://www.youtube.com/embed/zJYwQK86dXg"
          title="Directveilig.nl"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
iframe {
  @media only screen and (max-width: 766px) {
    width: 100%;
  }
}
</style>
