<template>
  <div>
    <Hero />
    <promotional-bar />
    <HetGrootste />
    <Vele />
    <Directveilig />
    <CVV />
    <Duizenden />
    <Footer />
  </div>
</template>

<script>
import HetGrootste from "./Hero/HetGrootste.vue";
import Hero from "./Hero/Hero.vue";
import Vele from "./Hero/Vele.vue";
import Directveilig from "./Hero/Directveilig.vue";
import CVV from "./Hero/CVV.vue";
import Duizenden from "./Hero/Duizenden.vue";
import Footer from "./Hero/footer.vue";
import PromotionalBar from "./PromotionalBar.vue";
export default {
  components: {
    HetGrootste,
    Hero,
    Vele,
    Directveilig,
    CVV,
    Duizenden,
    Footer,
    PromotionalBar,
  },
};
</script>

<style lang="scss"></style>
